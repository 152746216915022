@import url(https://fonts.googleapis.com/css?family=Orbitron:700);

/* Stars 
------------------------------------- */

#space,
.stars {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.stars {
    background-image: radial-gradient(
            2px 2px at 20px 30px,
            #eee,
            rgba(0, 0, 0, 0)
        ),
        radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 50px 160px, #ddd, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 90px 40px, #fff, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 130px 80px, #fff, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 160px 120px, #ddd, rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 200px 200px;
    animation: zoom 5s infinite;
    opacity: 0;
}

.stars:nth-child(1) {
    background-position: 50% 50%;
    animation-delay: 0s;
}

.stars:nth-child(2) {
    background-position: 20% 60%;
    animation-delay: 1s;
}

.stars:nth-child(3) {
    background-position: -20% -30%;
    animation-delay: 2s;
}

.stars:nth-child(4) {
    background-position: 40% -80%;
    animation-delay: 3s;
}

.stars:nth-child(5) {
    background-position: -20% 30%;
    animation-delay: 4s;
}

@keyframes zoom {
    0% {
        opacity: 0;
        transform: scale(0.5);
        animation-timing-function: ease-in;
    }

    85% {
        opacity: 1;
        transform: scale(2.8);
        animation-timing-function: linear;
    }

    100% {
        opacity: 0;
        transform: scale(3.5);
    }
}

/* Demo syles 
------------------------------------- */

body {
    background: #000;
    font-family: "Orbitron", sans-serif;
}
.body-center {
    z-index: 1;
    position: absolute;
    top: 45%;
    left: 40%;
    margin: -1.5em -2.5em;
    animation: message 10s infinite;
}

.body-center .social-container {
    margin-left: 0.25em;
}

.body-center .social-container a {
    padding: 0.5em;
    color: #4a9cb2;
    font-size: 200%;
}

.body-center .social-container a:first-of-type {
    padding: 0.5em;
    color: #c74c72;
    font-size: 200%;
}
.copyright {
    font-size: 0.5em;
    color: #1d5261;
    margin-left: 1.5em;
}

h1 {
    /* z-index: 1; */
    width: 5em;
    color: #011;
    font-size: 1.75em;
    text-align: center;
    text-shadow: 0 0 2px #6cf;
    text-shadow: 0 0 2px #61dafb;
}

h1:first-line {
    font-size: 200%;
}


@keyframes message {
    0% {
        opacity: 0;
        transform: scale(0);
        animation-timing-function: ease-in;
    }

    10% {
        opacity: 1;
        transform: scale(0.1);
        animation-timing-function: ease-in;
    }

    20% {
        opacity: 1;
        transform: scale(0.2);
        animation-timing-function: ease-in;
    }

    25% {
        opacity: 1;
        transform: scale(0.3);
        animation-timing-function: linear;
    }

    30% {
        opacity: 1;
        transform: scale(0.4);
        animation-timing-function: linear;
    }

    35% {
        opacity: 1;
        transform: scale(0.5);
        animation-timing-function: linear;
    }

    40% {
        opacity: 1;
        transform: scale(0.6);
        animation-timing-function: linear;
    }

    45% {
        opacity: 1;
        transform: scale(0.7);
        animation-timing-function: linear;
    }

    50% {
        opacity: 1;
        transform: scale(0.8);
        animation-timing-function: linear;
    }

    55% {
        opacity: 1;
        transform: scale(0.9);
        animation-timing-function: linear;
    }

    60% {
        opacity: 1;
        transform: scale(1);
        animation-timing-function: linear;
    }

    65% {
        opacity: 0.7;
        transform: scale(1.5);
        animation-timing-function: linear;
    }

    70% {
        opacity: 0.6;
        transform: scale(2);
        animation-timing-function: linear;
    }

    75% {
        opacity: 0.5;
        transform: scale(2.5);
        animation-timing-function: linear;
    }

    80% {
        opacity: 0.4;
        transform: scale(3);
        animation-timing-function: linear;
    }

    80% {
        opacity: 0.3;
        transform: scale(3.5);
        animation-timing-function: linear;
    }

    90% {
        opacity: 0.2;
        transform: scale(4);
        animation-timing-function: linear;
    }

    95% {
        opacity: 0.1;
        transform: scale(4.5);
        animation-timing-function: linear;
    }

    100% {
        opacity: 0;
        transform: scale(5);
    }
}

/* @-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
} */

/* @keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
} */
